import React from "react";

import './styles/global.css';
import RoutesApp from "./routes";

function App(){
  return(
    
      <RoutesApp/>
      
  )
}


export default App